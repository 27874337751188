import RouteNames from './RouteNames';
import { createRouter, createWebHistory } from 'vue-router';

const Firewall = () => import('../components/pages/Firewall.vue');
const DevOps = () => import('../components/pages/DevOps.vue');
const Explorer = () => import('../components/pages/Explorer.vue');
const Security = () => import('../components/pages/Security.vue');
const Logs = () => import('../components/pages/Logs.vue');
const SecurityUsers = () => import('../components/pages/security/UsersView.vue');
const SecurityUserToRoles = () => import('../components/pages/security/UsersToRolesView.vue');
const SecurityRoles = () => import('../components/pages/security/RolesView.vue');
const SecurityModules = () => import('../components/pages/security/ModuleSecurityView.vue');
const SecurityUserReports = () => import('../components/pages/security/UserReportsView.vue');
const ApiTokens = () => import('../components/pages/security/TokenView.vue');
const SecurityProfile = () => import('../components/pages/security/securityProfile/SecurityProfile.vue');
const AuditTrail = () => import('../components/pages/logs/AuditTrailView.vue');
const HierMgr = () => import('../components/pages/logs/TestGridView.vue');
const Data = () => import('../components/pages/Data.vue');
const DataMaps = () => import('../components/pages/data/DataMapsView.vue');
const EtlLogs = () => import('../components/pages/logs/EtlLogView.vue');
const MatchingUI = () => import('../components/pages/matching/MatchingUI.vue');
const MemberMappingsView = () => import('../components/pages/data/MemberMappingsView.vue');
const ModelMappings = () => import('../components/pages/data/ModelMappingView.vue');
const ClearDataView = () => import('../components/pages/data/ClearDataView.vue');
const FieldMappingsView = () => import('../components/pages/data/FieldMappingsView.vue');
const ConnectionStrings = () => import('../components/pages/data/ConnectionStrings.vue');
const StagingTables = () => import('../components/pages/data/StagingTables.vue');
const StagingTableDetails = () => import('../components/pages/data/StagingTableDetails.vue');
const Homepages = () => import('../components/pages/data/Homepages.vue');
const JournalConfig = () => import('../components/pages/data/JournalConfig.vue');
const HomepageDesigner = () => import('../components/pages/data/HomepageDesigner.vue');
const Workflow = () => import('../components/pages/Workflow.vue');
const Contributor = () => import('../components/pages/Contributor.vue');
const Models = () => import('../components/pages/Models.vue');
const ModelView = () => import('../components/pages/metadata/models/MetadataModelsView.vue');
const DimensionView = () => import('../components/pages/metadata/dimensions/DimensionView.vue');
//const DimensionListEditor = () => import('../components/pages/metadata/dimensions/DimensionListEditor.vue');
const DimensionHierarchyView = () => import('../components/pages/metadata/dimensions/DimensionHierarchyView.vue');
const ModelViewsView = () => import('../components/pages/metadata/views/ModelViewGrid.vue');
const ModelViewEditor = () => import('../components/pages/metadata/views/ModelViewEditor.vue');
const ModelSubsetsView = () => import('../components/pages/metadata/subsets/Subsets.vue');
const ModelListsView = () => import('../components/pages/metadata/lists/ListView.vue');
const ModelDimensionSecurityView = () => import('../components/pages/security/DimensionProfilesView.vue');
const PowerBI = () => import('../components/pages/PowerBI.vue');
const XL3 = () => import('../components/pages/XL3.vue');
const Dashboard = () => import('../components/pages/Dashboard.vue');
const ReportBookEditor = () => import('../components/pages/ReportBookEditor.vue');
const Home = () => import('../components/pages/Home.vue');
const Notification = () => import('../components/pages/Notifications.vue');
const Journals = () => import('../components/pages/Journals.vue');
const JournalsEdit = () => import('../components/pages/journals/JournalsEdit.vue');
const JournalSelector = () => import('../components/pages/journals/JournalSelector.vue');
const JournalsReporting = () => import('../components/pages/journals/JournalsReporting.vue');
const JournalsAudit = () => import('../components/pages/journals/JournalsAudit.vue');
const MaintenanceEdit = () => import('../components/pages/maintenance/MaintenanceEdit.vue');
const MaintenanceReports = () => import('../components/pages/Maintenance.vue');
const MaintenanceFormListSelector = () => import('../components/pages/maintenance/FormListSelector.vue');
const MaintenanceTypeSelector = () => import('../components/pages/maintenance/MaintenanceTypeSelector.vue');
const Themes = () => import('../components/pages/data/Themes.vue');
const ThemeDetails = () => import('../components/pages/data/ThemeDetails.vue');
const WhatIsNewEditor = () => import('../components/pages/whatisnew/WhatIsNewEditor.vue');
const HomeExtension = () => import('../components/pages/HomeExtension.vue')


const routes: any[] =
    [
        { name: RouteNames.HomeRoute, path: '/', component: Home },
        { name: RouteNames.FirewallRoute, path: '/firewall', component: Firewall },
        { name: RouteNames.DevOpsRoute, path: '/devops', component: DevOps },
        { name: RouteNames.WhatIsNewEditor, path: '/whatisneweditor', component: WhatIsNewEditor },
        { name: RouteNames.ExplorerRoute, path: '/explorer', component: Explorer },
        {
            name: RouteNames.SecurityManagementRoute,
            path: '/security',
            component: Security,
            redirect: { name: RouteNames.SecurityManagementUsersRoute },
            children:
                [
                    { name: RouteNames.SecurityManagementUsersRoute, path: 'users', component: SecurityUsers },
                    {
                        name: RouteNames.SecurityManagementUserRolesRoute,
                        path: 'user_roles',
                        component: SecurityUserToRoles
                    },
                    { name: RouteNames.SecurityManagementRolesRoute, path: 'roles', component: SecurityRoles },
                    { name: RouteNames.SecurityManagementModulesRoute, path: 'modules', component: SecurityModules },
                    {
                        name: RouteNames.SecurityManagementUserReportsRoute,
                        path: 'user_reports',
                        component: SecurityUserReports
                    },
                    { name: RouteNames.SecurityManagementApiTokenRoute, path: 'apitokens', component: ApiTokens },
                    {
                        name: RouteNames.SecurityManagementProfileRoute,
                        path: 'securityprofile',
                        component: SecurityProfile
                    }
                ]
        },
        {
            name: RouteNames.DataRoute,
            path: '/data',
            component: Data,
            redirect: { name: RouteNames.DataConnectionStringsRoute },
            children:
                [
                    {
                        name: RouteNames.DataConnectionStringsRoute,
                        path: 'connectionstrings',
                        component: ConnectionStrings
                    },
                    { name: RouteNames.DataStagingTableRoute, path: 'stagingtables', component: StagingTables },
                    { name: RouteNames.DataModelMappingsRoute, path: 'modelmappings', component: ModelMappings },
                    { name: RouteNames.DataMapsRoute, path: 'datamaps', component: DataMaps },
                    { name: RouteNames.DataHomepagesRoute, path: 'homepages', component: Homepages },
                    { name: RouteNames.DataJournalsRoute, path: 'journalconfig', component: JournalConfig },
                    { name: RouteNames.DataThemesRoute, path: 'themes', component: Themes }
                ]
        },
        {
            name: RouteNames.DataStagingTableEditRoute,
            path: '/data/stagingtables/edit',
            component: StagingTableDetails
        },
        { name: RouteNames.DataThemeDetailsRoute, path: '/data/themes/edit', component: ThemeDetails },
        { name: RouteNames.DataHomepageDesignerRoute, path: '/data/homepages/edit', component: HomepageDesigner },
        { name: RouteNames.MemberMappingsViewRoute, path: '/data/membermappings/view', component: MemberMappingsView },
        { name: RouteNames.ClearDataViewRoute, path: '/data/modelmappings/cleardata', component: ClearDataView },
        {
            name: RouteNames.FieldMappingsViewRoute,
            path: '/data/modelmappings/fieldmappings',
            component: FieldMappingsView
        },
        { name: RouteNames.WorkflowContributorRoute, path: '/contributor', component: Contributor },
        { name: RouteNames.WorkflowRoute, path: '/workflow', component: Workflow },
        {
            name: RouteNames.ModelsRoute,
            path: '/metadata',
            component: Models,
            redirect: { name: RouteNames.ModelViewRoute },
            children:
                [
                    { name: RouteNames.ModelViewRoute, path: 'models', component: ModelView },
                    { name: RouteNames.DimensionViewRoute, path: 'dimensions', component: DimensionView },
                    { name: RouteNames.ModelViewsViewRoute, path: 'views', component: ModelViewsView },
                    { name: RouteNames.ModelSubsetsRoute, path: 'subsets', component: ModelSubsetsView },
                    { name: RouteNames.ModelListsRoute, path: 'lists', component: ModelListsView },
                    {
                        name: RouteNames.ModelDimensionSecurityRoute,
                        path: 'security',
                        component: ModelDimensionSecurityView
                    }
                ]
        },
        {
            name: RouteNames.DimensionHierarchyViewRoute,
            path: '/metadata/dimensions/edit',
            component: DimensionHierarchyView
        },
        { name: RouteNames.ModelViewEditorRoute, path: '/metadata/views/edit', component: ModelViewEditor},
        { name: RouteNames.PowerBIRoute, path: '/report/:pathMatch(.*)', component: PowerBI },
        { name: RouteNames.XL3Route, path: '/xl3/:pathMatch(.*)', component: XL3 },
        { name: RouteNames.DashboardRoute, path: '/dashboard', component: Dashboard },
        { name: RouteNames.MainFallback, path: '/workflow/contributor', component: Contributor },
        { name: RouteNames.ReportBookRoute, path: '/reportbook', component: ReportBookEditor },
        {
            name: RouteNames.MaintenanceRoute,
            path: '/maintenance',
            component: MaintenanceReports,
            redirect: { name: RouteNames.MaintenanceTypeSelectorRoute },
            children:
                [{ name: RouteNames.MaintenanceTypeSelectorRoute, path: 'type', component: MaintenanceTypeSelector },
                    {
                        name: RouteNames.MaintenanceFormListSelectorRoute,
                        path: 'list',
                        component: MaintenanceFormListSelector
                    },
                    { name: RouteNames.MaintenanceEditRoute, path: 'edit', component: MaintenanceEdit }]
        },
        { name: RouteNames.NotificationRoute, path: '/notifications', component: Notification },
        {
            name: RouteNames.JournalsRoute,
            path: '/journals',
            component: Journals,
            redirect: { name: RouteNames.JournalsSelectRoute },
            children:
                [
                    { name: RouteNames.JournalsSelectRoute, path: 'select', component: JournalSelector },
                    { name: RouteNames.JournalsEditRoute, path: 'edit', component: JournalsEdit },
                    { name: RouteNames.JournalsReportingRoute, path: 'reporting', component: JournalsReporting },
                    { name: RouteNames.JournalsAuditRoute, path: 'auditsummary', component: JournalsAudit }
                ]
        },
        {
            name: RouteNames.LogsRoute,
            path: '/logs',
            component: Logs,
            redirect: { name: RouteNames.LogManagementEtlLogsRoute },
            children:
                [
                    { name: RouteNames.LogManagementEtlLogsRoute, path: 'etllogs', component: EtlLogs },
                    { name: RouteNames.LogManagementAuditTrailRoute, path: 'audittrail', component: AuditTrail },
                    { name: RouteNames.TestGridRoute, path: 'hiermgr', component: HierMgr }
                ]
        },
        { name: RouteNames.MatchingUI, path: '/matching', component: MatchingUI },
        { name: RouteNames.HomeExtensionRoute, path: '/extension/:id(.*)/:customdata?', component: HomeExtension },

    ];

const router = createRouter({ 'history': createWebHistory(), routes });

export default router;

