import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from "../api/ApiClientBase";
import * as ext from "../api/Extensions";
import * as api from "../api/ApiClient";

export interface IUserManagementStore {
    Users: api.UserObject[] | null,
    Roles: api.RoleObject[] | null,
    Logins: api.UserObject[] | null,
    AllowedDomains: string[],
    DatesAdded: api.UserObject[] | null,
    ModuleSecurityEntries: api.ModuleSecurityInfo[] | null,
    SelectedUser: api.UserObject | null;
    SelectedRole: api.RoleObject | null;
    UserListShortRequested: boolean;
    UserListShort: api.UserDetailsShort[] | null;
    UserImportErrors: api.UserImportResult[] | null;
}

const UserManagementStore: Module<IUserManagementStore, any> = {
    namespaced: true,
    state: {
        Roles: null,
        Users: null,
        AllowedDomains: [],
        Logins: null,
        DatesAdded: null,
        ModuleSecurityEntries: null,
        SelectedUser: null,
        SelectedRole: null,
        UserListShort: null,
        UserListShortRequested: false,
        UserImportErrors: null,
    },
    mutations: {
        SetUsers(state, users: api.UserObject[]) {
            if (users != null) {
                users = users.sort((a, b) => a.userName!.localeCompare(b!.userName!));
            }
            state.Users = users;
        },
        SetAllowedDomains(state, allowedDomains: string[]) {
            state.AllowedDomains = allowedDomains;
        },
        SetLogins(state, logins: api.UserObject[]) {
            if (logins != null) {
                logins = logins.sort((a, b) => a.userName!.localeCompare(b!.userName!));
            }
            state.Logins = logins;
        },
        SetDatesAdded(state, datesAdded: api.UserObject[]) {
            state.DatesAdded = datesAdded;
        },
        SetRoles(state, roles: api.RoleObject[]) {
            state.Roles = roles;
        },
        SetModuleSecurityEntries(state, entries: api.ModuleSecurityInfo[]) {
            state.ModuleSecurityEntries = entries;
        },
        SetSelectedUser(state, user: api.UserObject) {
            state.SelectedUser = user;
        },
        SetSelectedRole(state, role: api.RoleObject) {
            state.SelectedRole = role;
        },
        SetUserListShort(state, users: api.UserDetailsShort[]) {
            state.UserListShort = users;
            if (users == null)
                state.UserListShortRequested = false;
        },
        SetUserListShortRequested(state, requested: boolean) {
            state.UserListShortRequested = requested;
        },
        SetUserImportError(state, list: api.UserImportResult[]) {
            state.UserImportErrors = list;
        }
    },
    actions: {
        async LoadAllSecurityViews(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();

            await this.dispatch("usermanagement/LoadUsersAndRoles", auth_client);
            await this.dispatch("usermanagement/LoadModuleSecurityEntries", auth_client);
        },

        async LoadUsersAndRoles(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            await this.dispatch("usermanagement/LoadUsers", auth_client);
            await this.dispatch("usermanagement/LoadRoleDetails", auth_client);

            context.commit("SetSelectedUser", null);
            context.commit("SetSelectedRole", null);
        },

        async LoadUsers(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            var client = new api.UserClient(auth_client);
            var users = await client.getUserDetails();
            users.sort((a, b) => a.userName!.localeCompare(b.userName!));

            var allowedDomains = await client.getAllowedUserDomains();
            if (allowedDomains == null)
                allowedDomains = [];
            context.commit("SetUsers", users);
            context.commit("SetAllowedDomains", allowedDomains);
        },

        async GetLogins(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            var client = new api.UserClient(auth_client);
            var logins = await client.getLastLoginsInTenant();
            logins.sort((a, b) => a.userName!.localeCompare(b.userName!));
            context.commit("SetLogins", logins);

        },

        async GetDatesAdded(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            var client = new api.UserClient(auth_client);
            var dates = await client.getDateAdded();
            context.commit("SetDatesAdded", dates);

        },

        async LoadRoles(context, auth_client: AuthClient | null = null) {
            // does not require any security modules

            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            var client = new api.RoleClient(auth_client);
            var roles = await client.getRoles();
            context.commit("SetRoles", roles);
        },

        async LoadRoleDetails(context, auth_client: AuthClient | null = null) {
            // requires the Security Management module

            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            var client = new api.RoleClient(auth_client);
            var roles = await client.getRoleDetails();
            context.commit("SetRoles", roles);
        },

        async LoadModuleSecurityEntries(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            var client = new api.SecurityClient(auth_client);
            var entries = await client.getRoleModuleSecurity();
            context.commit("SetModuleSecurityEntries", entries);
        },

        async DeleteSelectedUser(context) {
            try {
                var users = context.state.Users;
                var roles = context.state.Roles;
                var user = context.state.SelectedUser;
                if (users != null && roles != null && user != null && user.userName != null) {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();

                    var client = new api.UserClient(auth_client);
                    client.removeUser(user.userName);

                    var idx = -1;
                    for (var i = 0; i < users.length; i++) {
                        if (users[i].userName == user.userName) {
                            idx = i;
                            break;
                        }
                    }

                    if (idx > -1)
                        users.splice(idx, 1);

                    for (var i = 0; i < roles.length; i++) {
                        roles[i].users = roles[i].users!.filter(u => u != user!.userName);
                    }

                    context.commit("SetSelectedUser", null);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        type: api.ToastType.Info,
                        message: context.rootState.Localizer.Localize("UserManagementStore_User_deleted")
                    }))
                }
            }
            catch (err) {
                this.dispatch("ShowToastError", err);
            }
        },

        async SaveUser(context, userDetails: api.UserObject) {
            var username = userDetails.userName;
            var users = context.state.Users;
            var roles = context.state.Roles;

            if (username != null && users != null && roles != null) {
                try {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.UserClient(auth_client);
                    var saved = userDetails.userID === 0 ? await client.addUser(userDetails) : await client.editUser(userDetails);

                    var idx = -1;
                    for (var i = 0; i < users.length; i++) {
                        if (users[i].userName == username) {
                            idx = i;
                            break;
                        }
                    }

                    if (idx > -1)
                        users.splice(idx, 1);
                    users.push(saved);
                    users.sort((a, b) => a.userName!.localeCompare(b.userName!));

                    for (var i = 0; i < roles.length; i++) {
                        if (userDetails.roles!.includes(roles[i].roleName!)) {
                            if (!roles[i].users!.includes(username))
                                roles[i].users!.push(username);
                        }
                        if (!userDetails.roles!.includes(roles[i].roleName!)) {
                            if (roles[i].users!.includes(username))
                                roles[i].users = roles[i].users!.filter(u => u != username);
                        }
                    }

                    context.commit("SetSelectedUser", saved);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        type: api.ToastType.Info,
                        message: context.rootState.Localizer.Localize("UserManagementStore_User_saved")
                    }))
                }
                catch (err) {
                    this.dispatch("ShowToastError", err);
                }
            }
        },

        async DeleteSelectedRole(context) {
            var users = context.state.Users;
            var roles = context.state.Roles;
            var role = context.state.SelectedRole;
            if (roles != null && users != null && role != null && role.roleName != null) {
                try {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();

                    var client = new api.RoleClient(auth_client);
                    client.removeRole(role.roleName);

                    var idx = -1;
                    for (var i = 0; i < roles.length; i++) {
                        if (roles[i].roleName == role.roleName) {
                            idx = i;
                            break;
                        }
                    }

                    if (idx > -1)
                        roles.splice(idx, 1);

                    context.commit("SetSelectedRole", null);

                    for (var i = 0; i < users.length; i++) {
                        users[i].roles = users[i].roles!.filter(r => r != role!.roleName)
                    }
                    this.dispatch("ShowToast", new api.ToastNotification({
                        type: api.ToastType.Info,
                        message: context.rootState.Localizer.Localize("UserManagementStore_Role_deleted")
                    }));
                }
                catch (err) {
                    this.dispatch("ShowToastError", err);
                }
            }
        },

        async SaveRole(context, roleDetails: ext.Pair<api.RoleObject>) {
            try {
                var rolename = roleDetails.edited.roleName;
                var roles = context.state.Roles;
                var users = context.state.Users;

                if (rolename == null || roles === null || users == null)
                    return;

                var auth_client = new AuthClient();
                await auth_client.ensureToken();

                var client = new api.RoleClient(auth_client);

                var getUser = function (userName: string): api.UserObject | null {
                    for (var i = 0; i < users!.length; i++)
                        if (users![i].userName === userName)
                            return users![i];
                    return null;
                };

                var addedUsers: api.UserObject[] = [];
                for (var i = 0; i < roleDetails.edited.users!.length; i++) {
                    var username = roleDetails.edited.users![i];
                    if (!roleDetails.original.users!.includes(username)) {
                        addedUsers.push(getUser(username)!);
                    }
                }

                var removedUsers: api.UserObject[] = [];
                for (var i = 0; i < roleDetails.original.users!.length; i++) {
                    var username = roleDetails.original.users![i];
                    if (!roleDetails.edited.users!.includes(username)) {
                        removedUsers.push(getUser(username)!);
                    }
                }

                if (roleDetails.original.roleid === 0) {
                    var saved = await client.addRole(roleDetails.edited.roleName!, roleDetails.edited);
                    if (addedUsers.length > 0) {
                        await client.addUsersToRole(roleDetails.edited.roleName!, addedUsers)
                    }
                    roleDetails.edited.roleid = saved.roleid;
                }
                else
                    if (addedUsers.length > 0)
                        await client.addUsersToRole(rolename, addedUsers);

                if (removedUsers.length > 0)
                    await client.removeUsersFromRole(rolename, removedUsers);

                var idx = -1;
                for (var i = 0; i < roles.length; i++) {
                    if (roles[i].roleName == rolename) {
                        idx = i;
                        break;
                    }
                }

                for (var i = 0; i < addedUsers.length; i++) {
                    addedUsers[i].roles!.push(rolename);
                }

                for (var i = 0; i < removedUsers.length; i++) {
                    removedUsers[i].roles != removedUsers[i].roles!.filter(r => r != rolename);
                }

                if (idx > -1)
                    roles.splice(idx, 1);
                roles.push(roleDetails.edited);
                roles.sort((a, b) => a.roleName!.localeCompare(b.roleName!));

                //context.commit("SetRoles", roles);
                context.commit("SetSelectedRole", roleDetails.edited);

                await this.dispatch("ShowToast", new api.ToastNotification({
                    type: api.ToastType.Info,
                    message: context.rootState.Localizer.Localize("UserManagementStore_Role_saved")
                }));
            }
            catch (err) {
                await this.dispatch("ShowToastError", err);
            }
        },

        async SaveModuleSecurity(context, entries: api.ModuleSecurityInfo[]) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.SecurityClient(auth_client);
            await client.setRoleModuleSecurity(entries);
            context.commit("SetModuleSecurityEntries", entries);
        },

        async LoadUserListShort(context) {
            if (context.state.UserListShortRequested)
                return;
            if (context.state.UserListShort == null) {
                context.commit("SetUserListShortRequested", true);
                var auth_client = new AuthClient();
                await auth_client.ensureToken();

                var client = new api.UserClient(auth_client);
                var entries = await client.getUserShort();
                context.commit("SetUserListShort", entries);
            }
        },

        async Import(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }

            //fileParameter = { data: fileList[0], fileName: fileList[0].name };
            var client = new api.UserClient(auth_client);
            //var response = await client.import(this.csvDelim, fileParameter); // don't care about file name?
        },
    },
    getters: {
        Roles: (store: IUserManagementStore) => store.Roles,
    }
};

export default UserManagementStore;
