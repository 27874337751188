import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import Themes from "../api/Themes"
import router from "../routing/Router";
import { Profile } from "@/components/pages/security/SecurityProfileClasses";

export interface ISecurityStore {
    UserName: string,
    AvatarVersion: number,
    Tenants: api.Tenant[] | null
    Tenant: api.Tenant | null,
    UserData: api.ExtendedUserData | null,
    ModuleSecurity: api.ModuleAccess[] | null,
    SelectedMember: number | null,
    ServerSelectionNeeded: boolean,
    Profiles: Array<Profile>,
}

const SecurityStore: Module<ISecurityStore, any> = {
    namespaced: true,
    state: {
        UserName: "",
        AvatarVersion: 1,
        Tenant: null,
        UserData: null,
        Tenants: null,
        ModuleSecurity: null,
        SelectedMember: null,
        ServerSelectionNeeded: true,
        Profiles: [],
    },
    getters: {
        Profiles: (state: ISecurityStore) => state.Profiles
    },
    mutations: {
        SetUserName(state, UserName: string) {
            state.UserName = UserName;
        },
        IncreaseAvatarVersion(state) {
            state.AvatarVersion = state.AvatarVersion + 1;
        },
        SetTenants(state, tenants: api.Tenant[] | null) {
            state.Tenants = tenants;
        },
        SetTenant(state, tenant: api.Tenant | null) {
            state.Tenant = tenant;
        },
        SetUserData(state, userData: api.ExtendedUserData | null) {
            state.UserData = userData;
            if (userData != null)
                state.UserName = userData.userName!;
        },
        SetModuleSecurity(state, moduleSecurity: api.ModuleAccess[] | null) {
            state.ModuleSecurity = moduleSecurity;
        },
        SetSelectedMember(state, member: number | null) {
            state.SelectedMember = member;
        },
        SetApiToken(state, apiToken: string) {
            state.UserName = "API Token"
            AuthClient.apiToken = apiToken;
        },
        SetServerSelectionNeeded(state, val: boolean) {
            state.ServerSelectionNeeded = val
        },
        SetProfiles(state, payload: Array<Profile>) {
            state.Profiles = payload;
        }
    },
    actions: {
        async Login(context, prefferedTenant: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            if (AuthClient.accessToken == null)
                await auth_client.login(window.location.hash.replace("#/", ""));

            if (AuthClient.apiToken == null) {
                if (AuthClient.accessToken == null)
                    return; // the login code will kick in and redirect the user to the log in page...

                //await auth_client.ensureToken();
                if (auth_client.state != null && auth_client.state != "") {
                    router.push(auth_client.state);
                }
                context.commit("SetUserName", AuthClient.account!.userName);
                console.log("Security/Login - Username is: " + AuthClient.account!.userName);
            }

            var client = new api.SecurityClient(auth_client);
            console.log("Security/Login - getting tenants");
            var tenants = await client.getUserTenants();
            tenants.forEach(t => {
                if (t.environment == null) t.environment = "Unknown";
            });
            context.commit("SetTenants", tenants);

            if (prefferedTenant != null) {
                prefferedTenant = prefferedTenant.toLowerCase();
                for (let idx in tenants) {
                    let tenant = tenants[idx];
                    if (tenant.name != null && tenant.name.toLowerCase() === prefferedTenant) {
                        await context.dispatch("SelectTenant", tenant);
                        break;
                    }
                }
            }
            else if (tenants.length === 1) {
                await context.dispatch("SelectTenant", tenants[0]);
            }
        },

        async LoadUserTenants(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();

            var client = new api.SecurityClient(auth_client);
            console.log("Security/Login - getting tenants");
            var tenants = await client.getUserTenants();
            tenants.forEach(t => {
                if (t.environment == null) t.environment = "Unknown";
            });
            context.commit("SetTenants", tenants);

            if (context.state.Tenant != null)
                for (var i = 0; i < length; i++)
                    if (tenants[i].name == context.state.Tenant.name) {
                        await context.dispatch("SelectTenant", tenants[i]);
                        return;
                    }
            await context.dispatch("SelectTenant", null);

        },

        async SelectTenant(context, tenant: api.Tenant) {
            console.log("Security/SelectTenant - " + tenant?.name);
            context.commit("SetTenant", tenant);
            if (tenant !== null && tenant.name != null && tenant.webApiUrl != null)
                AuthClient.setTenant(tenant);
            else {
                AuthClient.setTenant(tenant);
                context.commit("SetUserData", null);
                return;
            }

            var auth_client = new AuthClient();
            var client = new api.SecurityClient(auth_client);
            var userData = await client.getUserData();
            context.commit("SetUserData", userData);
            if (userData.themeName) {
                await context.dispatch("SetCustomTheme", { theme: userData.themeName, defaultTheme: false }, { root: true });
            } else {
                await context.dispatch("SetCustomTheme", { theme: Themes.ThemeLight, defaultTheme: true }, { root: true });
            }
            if (userData.welcomeMessage)
                await this.dispatch("ShowToast", new api.ToastNotification({
                    message: userData.welcomeMessage,
                    type: api.ToastType.Info
                }));

            var moduleAccess = await client.getEffectiveModuleSecurity();
            context.commit("SetModuleSecurity", moduleAccess);

            context.commit("NewNotificationHandler", null, { root: true });
            context.dispatch("NewLocalizer", null, { root: true });

            await context.dispatch("chat/LoadHelpArticles", null, { root: true });
        },

        async LoadModuleSecurity(context) {
            var auth_client = new AuthClient();
            var client = new api.SecurityClient(auth_client);
            var moduleAccess = await client.getEffectiveModuleSecurity();
            context.commit("SetModuleSecurity", moduleAccess);
        },

        async LoadProfiles(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }
            const client = new api.SecurityClient(auth_client);
            const profiles = await client.getDimensionSecurityProfiles();
            const profilesInstances = profiles.map(p => new Profile(p));
            context.commit('SetProfiles', profilesInstances);
        },

        async LogOut(context) {
            try {
                var auth_client = new AuthClient();
                auth_client.ensureToken();
                var client = new api.SecurityClient(auth_client);
                await client.logout()
            }
            catch { }
            var auth_client = new AuthClient();
            await auth_client.logout();
        },

        async SwitchUser(context) {
            try {
                var auth_client = new AuthClient();
                auth_client.ensureToken();
                var client = new api.SecurityClient(auth_client);
                await client.logout()
            }
            catch { }
            var auth_client = new AuthClient();
            await auth_client.forceLogin();
        }
    }
};

export default SecurityStore;
